@media screen and(min-width:1px) and(max-width:1440px){
    div {
        &.menu-popup {
            padding-top: 30px;
            .menu-search { margin-bottom: 50px; }
        }
    }
}
@media screen and(min-width:1024px){
    section.has-socmed { padding-top: 70px; }
}
@media screen and(min-width:1024px){

}
@media screen and(min-width:1024px) and(max-width:1365px){
    section.coverage .media-container .list .inner p.coverage-title a { height: 90px !important; }
    section.about-intro { padding-bottom: 100px; }
}
@media screen and(min-width:1px) and(max-width:1680px){
    section {
        &.testimonial { padding-top: 35px; }
        &.coverage {
            .media-container {
                .list {
                    .inner {
                        p.coverage-title {
                            a { height: 95px; display: block; overflow: hidden; }
                        }
                    }
                }
            }
        }
    }
}
@media screen and(min-width:1366px) and(max-width:1680px){
    div {
        &.wrapper { width: 92.5%; }
        &.title {
            h6 { font-size: 20px; }
            h2 { font-size: 50px; }
            p { font-size: 16px; }
        }
        &.price-list {
            .price {
                p {
                    font-size: 29px; margin: 5px 0px;
                }
            }
            .list {
                table {
                    tr td {
                        padding: 4px 5px; font-size: 16px;
                    }
                }
            }
        }
        &.file-download {
            padding: 0px 0px 26px 0px; margin-bottom: 14px;
            .file-title { margin: 10px 0px; font-size: 18px; }
        }
        &.menu-popup{
            .menu-language{right: 85px;top: 0px;}
        }
    }
    b {
        &.title, &.title-slider {
            font-size: 40px; line-height: 45px;
            &.hasborder {
                &::after { bottom:-5px; }
            }
        }
    }
    header {
        div.left {
            margin-top: 7px;
            .logo img { max-height: 57px;  }
            ul {
                bottom: -8px;
                li {
                    padding: 10px 12px;
                    a { font-size: 15px; }
                }
            }
        }
        div.right {
            margin-top: 5px;
            .join { font-size: 13px; padding: 9px 5px; }
            .hamburger { top: -4px; margin-left: 20px; }
            .language {
                right: 105px; top: 12px;
                ul { min-width: 120px; }
            } 
        }
        &.active{
            div.right{
                .language{top:7px}
                .hamburger{top: -9px;}
            }
        }
    }
    section {
        &.main-slider {
            .main-slides .slick-dots button { margin: 10px 0px; }
            .basic {
                .caption {
                    h1 { font-size: 56px; }
                    p { font-size: 19px; margin:23px 0px; }
                }
            }
            .video {
                .caption {
                    .inner { padding: 0px 122px 0px 179px; }
                    .play { right: -36px; width: 70px; height: 68px; 
                            &:hover { width: 60px; height: 59px; right: -31px; }
                    }
                    h1 { font-size: 40px; line-height: 55px; 
                         span.border { bottom: -20px; }
                    }
                }
            }
        }
        &.program {
            .decor-2 { display: none; }
            .caption-fixed {
                p {
                    font-size: 16px; line-height: 22px;
                }
            }
        }
        &.work {
            margin-bottom: 0px;
            div.work-list {
                .list {
                    &:last-child {
                        span.tooltip-text { left:-48%; }
                        span.tooltip-text::before { right: 30px; left: auto; }
                    }
                }
            }
        }
        &.testimonial {
            padding-bottom: 0px; margin-bottom: 80px;
            span.decor , span.decor-2 { display: none; }
            span.big-bg-decor { max-width: 1070px; }
            .highlight , .wrap-list { padding-top: 0px; margin-top: 15px;}
            .highlight { padding-right: 30px; }
            .wrap-list { padding-left: 30px;}
        }
        &.coverage {
            margin-bottom: 80px;
            .wrapper-decor {
                span.decor { display: none; }
            }
            .media-container {
                padding-bottom: 0px;
                span.decor-2 { display: none; }
            }
            .wrapper-fluid { padding-top: 30px; }
        }
        &.join {
            .decor , .decor-2 { display: none; }
        }
        &.banner {
            .left-content { width: 35%; 
                            .inner {
                                h1 { font-size: 30px; line-height: 45px; }
                            }
            }
            .img { margin-left: 35%; }
        }
        &.double-column , &.single-column {
            > .decor { display: none; }
            div.share-socmed, div.share-socmed {
                margin-bottom: 20px;
            }
            aside {
                ul.socmed-contact{ margin-bottom: 40px; }
                ul.hasborder {
                    li {
                        a { font-size: 13px; 
                            &.active { font-size: 18px; }
                        }
                    }
                }
            }
        }
        &.double-column {
        }

        .slide-about-teams , .slide-about-intl {
            .video {
                .caption {
                    .inner { padding: 0px 100px 0px 30%; }
                    b { font-size: 28px; line-height: 32px; 
                        &::after { width:130px; }
                    }
                    .play { right: -36px; width: 70px; height: 68px; 
                            &:hover { width: 60px; height: 59px; right: -31px; }
                    }
                }
            }
        }
    }
}